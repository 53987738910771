<template>
  <section class="section__container">
    <div class="section__container_background">
      <img
        max-width="100%"
        height="100%"
        src="../../assets/conteudos_violacoes_direito_background_06.png"
        alt=""
      />

      <GoBack />
    </div>
    <div class="container position--relative">
      <section class="container">
        <v-row class="d-flex justify-center flex-column align-center">
          <div class="d-flex align-self-start pt-2 pb-2">
            <Breadcrumb
              style="color: white"
              class="conteudos_tematicos_section_bc-color"
            >
              <template v-slot:breadcrumbLinks>
                <router-link
                  tag="p"
                  :to="{ name: link.href }"
                  v-for="(link, idx) in breadcrumbItems"
                  :key="idx"
                >
                  {{ link.text }}
                </router-link>
              </template>
            </Breadcrumb>
          </div>
          <v-col class="d-flex justify-center align-center">
            <img
              width="50px"
              src="../../assets/conteudos_violacoes_direito_title_engine.png"
              alt=""
            />
            <h3 class="ml-3">
              Impossibilidade de alteração dos termos
            </h3>
          </v-col>
        </v-row>
      </section>
      <section class="container">
        <v-row class="d-flex justify-center align-center">
          <v-col cols="12" md="8">
            <Card
              borderColor="#426E51"
              backgroundColor="#F9F7ED"
              title="A impossibilidade de alteração dos termos firmados na fase de negociação extrajudicial (FNE)"
            >
              <template v-slot:conteudo>
                <p>
                  A participação de um núcleo familiar atingido pelo rompimento
                  da barragem de Fundão na Fase de Negociação Extrajudicial
                  (FNE) encerra-se com a assinatura do “Termo de Acordo”, quando
                  a família decide aceitar a proposta de indenização oferecida
                  pela Fundação Renova, apesar das conhecidas violações desse
                  processo.
                </p>
                <p>
                  O Termo de Acordo, elaborado exclusiva e unilateralmente pela
                  Fundação Renova, tem a função de formalizar o que foi definido
                  entre as partes como resultado das supostas “negociações”,
                  devendo ser, portanto, fiel às especificidades de cada caso,
                  de forma a detalhar o conteúdo e abrangência das propostas
                  indenizatórias aceitas pelo núcleo familiar em questão. Apesar
                  disso, o que se verifica na prática é a impossibilidade de
                  qualquer alteração nesse documento, independentemente da
                  vontade das pessoas atingidas.
                </p>
                <p>
                  A equipe de Assessoria Jurídica da Cáritas MG, durante os
                  acompanhamentos às atingidas e atingidos na FNE, percebeu em
                  diversas ocasiões a necessidade de alterações nos textos dos
                  Termos de Acordo, para corrigir informações ou promover
                  adequações do documento ao caso concreto. No entanto, as
                  manifestações nesse sentido são sempre negadas pela Fundação
                  Renova, sob a justificativa genérica e distorcida de que
                  quaisquer mudanças não poderiam ser feitas em respeito à
                  isonomia no tratamento às pessoas atingidas.
                </p>
                <p>
                  Percebe-se, então, que a assinatura do Termo de Acordo na
                  forma exata em que é apresentado é uma imposição da Fundação
                  Renova para que as famílias participantes da FNE possam
                  receber os valores de indenização propostos. Isso demonstra,
                  de forma clara, que a FNE não pode ser considerada um ambiente
                  de negociação de fato, porque negociar exige que se possa
                  discutir as discordâncias e buscar um meio termo que atenda a
                  ambas as partes.
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container">
        <v-row class="d-flex justify-center align-center mt-5">
          <v-col cols="12" md="8">
            <Card borderColor="#426E51" backgroundColor="#F9F7ED">
              <template v-slot:conteudo>
                <p>
                  Outro ponto importante a ser destacado diz respeito à forma
                  com que são exibidas as propostas indenizatórias no referido
                  Termo de Acordo. O documento, que deveria apresentar todos os
                  danos contemplados pela indenização oferecida, de forma
                  específica, faz referência apenas às categorias de danos que
                  estão sendo indenizados, de forma ampla e genérica. Essa
                  prática provoca graves prejuízos às pessoas atingidas, uma vez
                  que o Termo de Acordo prevê a quitação da indenização por
                  categorias de danos, e a falta de especificação de cada um dos
                  danos considerados na proposta impossibilita uma discussão
                  futura, acerca de danos não indenizados na FNE, considerados
                  inerentes a alguma categoria já citada no documento.
                </p>
                <p>
                  Ainda sobre a quitação, o Termo de Acordo prevê que ela
                  alcança também, para além da Fundação Renova, as empresas
                  Samarco, Vale, BHP Billiton e seus grupos econômicos, e que
                  nada mais poderá ser reclamado contra elas com relação às
                  categorias de danos consideradas indenizadas na FNE.
                </p>
                <p>
                  Por fim, mesmo sendo o Termo de Acordo um documento construído
                  de forma unilateral pela Fundação Renova, sem qualquer
                  participação das pessoas atingidas, e a elas imposto, nele
                  constam declarações a elas atribuídas, no sentido de afirmar
                  que “[...] a adesão à FNE é voluntária, livre de qualquer
                  vício [...]” e que “[...] possui(em) plena capacidade para
                  negociar, transacionar, concordar ou discordar dos termos e da
                  FNE”.
                </p>
                <p>
                  Dessa forma, observa-se que o Termo de Acordo imposto pela
                  Fundação Renova somente possibilita às famílias participantes
                  da FNE que o aceitem ou rejeitem (nesse caso, sem receber
                  qualquer indenização), tirando delas qualquer participação na
                  elaboração do documento. Trata-se, portanto, de um instrumento
                  de adesão, utilizado em momento de abalo físico, psíquico e
                  econômico das pessoas atingidas, para pressioná-las a admitir
                  não só o conteúdo, mas também a forma do que é oferecido pela
                  Fundação Renova.
                </p>
                <p>
                  Convida-se à leitura do
                  <a
                    target="_blank"
                    style="text-decoration: none"
                    href="https://www.google.com/url?q=http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf&sa=D&source=docs&ust=1653146093495057&usg=AOvVaw0U-VdAtCGHL5Bp8DqQcJmC"
                    class="pink--text"
                    >Parecer Técnico Jurídico</a
                  >
                  produzido pela equipe de Assessoria Jurídica da Cáritas MG, em
                  que foram relatadas, com mais detalhes, as práticas e
                  violações da Fundação Renova, no âmbito da FNE, perpetradas a
                  fim de negar a
                  <router-link
                    tag="a"
                    style="text-decoration: none"
                    class="pink--text"
                    :to="{ name: 'conteudos_tematicos_luta_reparacao' }"
                    >reparação integral</router-link
                  >
                  dos danos decorrentes do rompimento da barragem de
                  Fundão<sup>1</sup>.
                </p>

                <p style="text-align: center">
                  <strong
                    >Autoria: Equipe de Assessoria Jurídica da ATI de
                    Mariana</strong
                  >
                </p>
              </template>
            </Card>
          </v-col>
        </v-row>
      </section>

      <section class="container pb-10 mt-4 section__mb">
        <v-row class="d-flex">
          <v-col cols="12">
            <div class="inside__card">
              <p class="ma-0">
                <sup>1</sup>
                <a
                  class="pink--text"
                  style="text-decoration: none"
                  href="http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf"
                  >http://mg.caritas.org.br/storage/arquivo-de-biblioteca/December2021/ZSzuQyeDEmzOnKWzaXL7.pdf</a
                >
              </p>
            </div>
          </v-col>
        </v-row>
      </section>

      <section class="container section__mb">
        <v-row class="d-flex justify-center align-center pa-4 section__mb">
          <v-col cols="6" class="d-flex justify-center align-center">
            <v-btn
              :to="{ name: 'conteudos_tematicos_violacoes_direitos' }"
              color="#EDBD43"
            >
              <strong>Voltar para o início de "Violações de Direitos"</strong>
            </v-btn>
          </v-col>
        </v-row>
      </section>
    </div>
  </section>
</template>

<script>
import Card from "@/components/Card.vue";
import Breadcrumb from "@/layout/Breadcrumb.vue";
import GoBack from "@/components/GoBack.vue";

export default {
  components: {
    Card,
    Breadcrumb,
    GoBack,
  },
  data() {
    return {
      breadcrumbItems: [
        {
          text: "< Conteúdos Temáticos / ",
          href: "conteudos_tematicos",
        },

        {
          text: "Violações de Direito /",
          href: "conteudos_tematicos_violacoes_direitos",
        },

        {
          text: "Impossibilidade de alteração dos termos",
          href: "conteudos_tematicos_violacoes_impossibilidade",
        },
      ],
    };
  },
  created() {},
};
</script>

<style scoped>
.inside__card {
  background-color: white;
  width: 100%;
  padding: 1em;
}

.inside__card p {
  font-size: 0.5em;
}

.v-btn {
  width: 80%;
  word-wrap: normal;
  white-space: nowrap;
  text-align: center;
}
.section__container {
  position: relative;
}

.section__container_background {
  position: fixed;
  top: 0;
}

h3 {
  color: white;
}

@media (max-width: 600px) {
  .section__container_background {
    position: relative;
    display: flex;
    justify-content: center;
  }
  h3 {
    color: black;
  }
}

@media (min-width: 1400px) {
  .section__container_background {
    display: flex;
    justify-content: center;
  }
}

.position--relative {
  position: relative;
}

.card__footer_img {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.card__footer_img img {
  width: 40%;
}

.card__footer_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  color: white;
}

.section__mb {
  padding-bottom: 3em;
}
</style>
